// import { Link } from 'gatsby'
import ArrowRightBoxIcon from 'mdi-react/ArrowRightBoxIcon'
// import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import * as React from 'react'
import { ContentSection } from '../components/content/ContentSection'
import { IntegrationsSection } from '../components/IntegrationsSection'
import Layout from '../components/Layout'
// import { CustomerLogosSectionAnimated } from '../components/product/CustomerLogosSectionAnimated'
// import GetStarted from '../components/GetStarted'
import '../locales/config'
import { Trans } from 'react-i18next'

const Index: React.FunctionComponent = (props: any) => (
    <Layout location={props.location}>
        <div className="home">
            <div className="home__hero mb-6">
                <div className="home__intro container">
                    <div className="row">
                        <div className="col mt-6 mb-6 mb-lg-0 text-center">
                            <h1 className="display-1 font-weight-bold mb-0">
                                <Trans i18nKey="index.maintitle" />
                            </h1>
                            <h2 className="display-3 mb-0">
                                <Trans i18nKey="index.secondtitle" />
                            </h2>
                            <p className="my-3 col-9 mx-auto">
                                有任何关于产品和使用的疑问，可以联系{' '}
                                <a href="/contact" title="codein services">
                                    CodeIN客服
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <CustomerLogosSectionAnimated showButton={true} showSection={true} className="pt-5" /> */}
            <ContentSection className="pt-6 mt-3 d-none d-sm-block">
                <div className="home__screenshot"></div>
            </ContentSection>

            <ContentSection className="mt-5 mb-6">
                <div className="row flex-wrap-reverse">
                    <div className="col-lg-5 mt-md-5">
                        <h2 className="display-4 font-weight-bold">强大的代码搜索查询语言</h2>
                        <p>
                            CodeIN支持跨仓库代码搜索,可以一次性全局修改方法或者变量。不仅如此,CodeIN还有丰富的语义查询语言,
                            帮助完成更加精准的搜索。例如：
                            <br /> <code className="border rounded px-1">文件名模糊匹配</code>,{' '}
                            <code className="border rounded px-1">编程语言筛选</code>,{' '}
                            <code className="border rounded px-1">仓库选择</code>,{' '}
                            <code className="border rounded px-1">方法名过滤</code>等等 <br />
                            另外,CodeIN还支持集成常见代码平台,让搜索更加方便
                        </p>
                        <div className="pt-1">
                            <a className="d-flex align-items-center" href="/docs/tour">
                                代码搜索教程 <ArrowRightBoxIcon className="icon-inline ml-1" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-5 pl-lg-4">
                        <img
                            src="/code-search-illustrated.svg"
                            className="home__diagram w-150"
                            alt="Code search across multiple code hosts, including GitHub, GitLab, BitBucket, and Azure"
                        />
                    </div>
                </div>
            </ContentSection>

            <ContentSection className="mt-5 mb-6">
                <div className="row">
                    <div className="col-lg-7">
                        <img src="/opensourcecode-lg.svg" className="home__diagram w-150" alt="Open Source Code" />
                    </div>
                    <div className="col-lg-5">
                        <h2 className="display-4 font-weight-bold">搜索开源仓库</h2>
                        <p>CodeIN公有云会为常见的开源仓库建立索引,方便大家随时随地搜索开源代码。</p>
                        <div className="pt-1">
                            <a className="btn btn-primary" href="https://codein.dev/search" title="Try codein">
                                立即搜索
                            </a>
                        </div>
                    </div>
                </div>
            </ContentSection>

            <IntegrationsSection />
            {/*
            <div className="container">
                <hr className="my-md-6" />
            </div> */}

            {/* <div className="container">
                <h2 className="display-2 font-weight-bold">How developers use codein</h2>
                <div className="row">
                    <div className="col-lg-6 mt-lg-0">
                        <p className="my-5">
                            codein is built by developers for developers, to help them solve the big code problems
                            they face, all day every day. Here's how they're doing it.
                        </p>
                    </div>
                    <div className="col-lg-6 mt-2 mt-lg-4">
                        <div className="card pt-0" style={{ background: "url('/customers-page-bg.svg') no-repeat" }}>
                            <strong className="card-header border-0 pb-0">Want to use codein at work?</strong>
                            <p className="card-body mb-0 pt-1">
                                <a href="#get-started" title="Try codein now">
                                    Use it free
                                </a>{' '}
                                for up to 10 developers. To get your company to upgrade to a{' '}
                                <Link to="/pricing">paid plan</Link>, see the{' '}
                                <Link to="/customers">customers page</Link> (to make the case). Or{' '}
                                <a href="/contact/request-info">schedule time with us</a> for help.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <ContentSection className="mt-6">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="text-uppercase mb-3">Code Search</div>
                        <h2>Find anything in code, fast</h2>
                        <div>
                            codein returns results in milliseconds, even across thousands of repositories, like:
                            <ul>
                                <li>
                                    Examples of{' '}
                                    <a href="https://codein.dev/search?q=repo:%5Egithub%5C.com/codein/+f:dockerfile+apt-get%7Capk&patternType=regexp">
                                        installing packages in a Dockerfile
                                    </a>
                                </li>
                                <li>Places where a specific error is returned</li>
                                <li>
                                    Recent TypeScript changes mentioning{' '}
                                    <code className="border rounded px-1">auth</code>
                                </li>
                                <li>Definitions of a specific function</li>
                            </ul>
                        </div>
                        <p>
                            Write queries with regex, punctuation, symbols, and advanced syntax-aware pattern matching.
                            codein’s visual and interactive query builder constructs complex queries to find and
                            filter code in ways IDEs and code hosts can’t.
                        </p>
                        <div className="pt-1">
                            <Link className="d-flex align-items-center" to="/code-search">
                                Learn more about code search <ArrowRightBoxIcon className="icon-inline ml-1" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-7 pl-lg-6 mt-3">
                        <div className="container video-embed embed-responsive embed-responsive-16by9 ">
                            <iframe
                                className="embed-responsive-item"
                                src="https://www.youtube-nocookie.com/embed/Iye0yZVr1Ro?autoplay=0&amp;cc_load_policy=0&amp;start=0&amp;end=0&amp;loop=0&amp;controls=1&amp;modestbranding=1&amp;rel=0"
                                allowFullScreen={true}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder={0}
                                title="AND/OR operators for universal code search"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </ContentSection> */}

            {/* <ContentSection className="mt-6">
                <div className="row flex-wrap-reverse">
                    <div className="col-lg-7 pr-lg-6 mt-3">
                        <div className="container video-embed embed-responsive embed-responsive-16by9 ">
                            <iframe
                                className="embed-responsive-item"
                                src="https://www.youtube-nocookie.com/embed/KSx61-yAMLs?autoplay=0&amp;cc_load_policy=0&amp;start=0&amp;end=0&amp;loop=0&amp;controls=1&amp;modestbranding=1&amp;rel=0"
                                allowFullScreen={true}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder={0}
                                title="Exploring code with codein"
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="text-uppercase mb-3">Code Intelligence</div>
                        <h2>Navigate code, with definitions and references</h2>
                        <p>
                            Find definitions, references, usage examples, and anything else in code, across package,
                            dependency, and repository boundaries. You can navigate code in your web browser in any
                            repository on any branch, instantly and without losing your local context.
                        </p>
                        <div className="pt-1">
                            <a
                                className="d-flex align-items-center"
                                href="https://docs.codein.dev/code_intelligence"
                            >
                                Code intelligence documentation <ArrowRightBoxIcon className="icon-inline ml-1" />
                            </a>
                        </div>
                    </div>
                </div>
            </ContentSection> */}

            {/* <ContentSection className="mt-6">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="text-uppercase mb-3">Batch Changes</div>
                        <h2>Make large-scale code changes</h2>
                        <p>
                            Remove legacy code, fix critical security issues, and pay down tech debt. Use Batch Changes
                            to compute diffs and create branches and pull requests across multiple repositories. With
                            codein, teams move fast and fix things, safely.
                        </p>
                        <div className="pt-1">
                            <a className="d-flex align-items-center" href="/batch-changes/">
                                Learn more about Batch Changes <ArrowRightBoxIcon className="icon-inline ml-1" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7 pl-lg-6 mt-3">
                        <div className="container video-embed embed-responsive embed-responsive-16by9 ">
                            <iframe
                                className="embed-responsive-item"
                                src="https://www.youtube-nocookie.com/embed/eOmiyXIWTCw?autoplay=0&amp;cc_load_policy=0&amp;start=0&amp;end=0&amp;loop=0&amp;controls=1&amp;modestbranding=1&amp;rel=0"
                                allowFullScreen={true}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder={0}
                                title="codein Batch Changes"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </ContentSection> */}

            {/* <GetStarted className="bg-gradient-green-blue mt-6" /> */}
        </div>
    </Layout>
)

export default Index
