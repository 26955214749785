import React from 'react'
import { ContentSection } from './content/ContentSection'

export const IntegrationsSection: React.FunctionComponent<{ className?: string }> = ({ className = '' }) => (
    <ContentSection className={className}>
        <h2 className="display-3 font-weight-bold mb-3">能力概览</h2>
        <div className="row">
            <div className="col-lg-6">
                <h3 className="h5 font-weight-bold pt-3">覆盖平台</h3>
                <ul className="list-inline d-inline-flex flex-wrap">
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">GitHub</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">GitHub Enterprise</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">GitLab</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Chrome</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Firefox</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">VS Code</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Atom</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Vim</code>
                    </li>
                </ul>
            </div>

            <div className="col-lg-6">
                <h3 className="h5 font-weight-bold pt-3">支持语言</h3>
                <ul className="list-inline d-inline-flex flex-wrap">
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Java</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Python</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Go</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">JavaScript</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">TypeScript</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">C#</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">C</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">C++</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Swift</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Objective-C</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Kotlin</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Ruby</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Scala</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Rust</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Perl</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Dart</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Erlang</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">COBOL</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Clojure</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Lisp</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Shell</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Terraform</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Lua</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">GraphQL</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Thrift</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Protobuf</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">YAML</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">JSON</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Jsonnet</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">R</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">PHP</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Elixir</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Haskell</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">PowerShell</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">OCaml</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">CUDA</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Pascal</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Verilog</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">VHDL</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Groovy</code>
                    </li>
                    <li className="list-inline-item text-nowrap">
                        <code className="border rounded px-1">Tcl</code>
                    </li>
                    <li className="d-block">
                        <a href="/contact" className="small text-muted">
                          没找到您使用的语言?
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </ContentSection>
)
