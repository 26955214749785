import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'

import cn_translation from './cn/translations.json'
import en_translation from './en/translations.json'

export const resources = {
    en: {
        translation: en_translation,
    },
    cn: {
        translation: cn_translation,
    },
} as const

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //   .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        // fallbackLng: 'en',
        fallbackLng: 'cn',
        lng: 'cn',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
    })

export { i18n as i18n }
